import { Component, OnDestroy, OnInit, inject } from '@angular/core'
import { ActivatedRoute, NavigationStart, Router } from '@angular/router'
import { Subscription, fromEvent } from 'rxjs'
import { MessageService } from 'primeng/api'
import { HostListener } from '@angular/core'
import { Location } from '@angular/common'
import { TranslateService } from '@ngx-translate/core'
import { Title } from '@angular/platform-browser'

@Component({
	selector: 'app-authentication-page',
	templateUrl: './authentication-page.component.html',
	styleUrl: './authentication-page.component.scss',
})
export class AuthenticationPageComponent implements OnInit, OnDestroy {
	languageCode: string | null = ''
	public selectedPage: string = 'login'
	public subscription: Subscription = new Subscription()
	public backFowardSubscription: Subscription = new Subscription()
	public params!: Map<string, string>
	public selectedLanguage!: { label: string; code: string }
	public languages: { label: string; code: string }[] = [
		{ label: 'Português', code: 'pt-PT' },
		{ label: 'English', code: 'en-UK' },
	]

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private _location: Location,
		private messageService: MessageService,
		private translate: TranslateService,
		private titleService: Title,
	) {}

	ngOnDestroy(): void {
		this.subscription.unsubscribe()
	}

	ngOnInit(): void {
		this.selectedLanguage =
			this.translate.currentLang === 'pt-PT'
				? this.languages[0]
				: this.languages[1]
		this.translate.onLangChange.subscribe(() => {
			this.selectedLanguage = this.languages.find(
				(lan) => lan.code === this.translate.currentLang,
			)!
		})
	}

	checkEncoding(url: string) {
		if (url.includes('%3F')) {
			//3F = ?
			url = url.replace('%3F', '?')
		}
		if (url.includes('%3D')) {
			//3D = =
			url = url.replace('%3D', '=')
		}
		return url
	}

	onLanguageChange() {
		this.translate.use(this.selectedLanguage.code)
		localStorage.setItem('language', this.selectedLanguage.code)
	}
}
