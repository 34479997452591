<!-- HTML -->
<div class="h-full w-full p-0 m-0">
	<div class="grid grid-nogutter h-full">
		<div class="fixed md:w-6 w-full h-full image-section"></div>
		<div class="absolute md:w-6 w-full h-full md:right-0">
			<div
				class="logo flex align-items-center md:align-items-end lg:align-items-end xl:align-items-end justify-content-center"
			>
				<img src="/assets/Mobicab_Logo.png" alt="Logo Mobicab" />
				<div class="languageSelector">
					<p-dropdown
						[options]="languages"
						[(ngModel)]="selectedLanguage"
						optionLabel="label"
						(onChange)="onLanguageChange()"
						[placeholder]="selectedLanguage.label"
						appendTo="body"
					>
						<ng-template pTemplate="selectedItem">
							<img
								[src]="
									'/assets/flags/' +
									selectedLanguage.code +
									'.svg'
								"
							/>
						</ng-template>
						<ng-template pTemplate="item" let-option>
							<div class="flex align-items-center">
								<img
									[src]="
										'/assets/flags/' + option.code + '.svg'
									"
									style="height: 1rem; margin-right: 0.5rem"
								/>
								{{ option.label }}
							</div>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="form-container">
				<div class="form-content">
					<router-outlet></router-outlet>
				</div>
			</div>
		</div>
	</div>
</div>
